
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/MaterialIcons-Regular.eot"); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url("../assets/fonts/MaterialIcons-Regular.woff2") format("woff2"),
  url("../assets/fonts/MaterialIcons-Regular.woff") format("woff"),
  url("../assets/fonts/MaterialIcons-Regular.ttf") format("truetype")
}

.material-icons {
  display: inline-block;
  font-family: 'Material Icons';
  font-size: 24px;  /* Preferred icon size */
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;

  direction: ltr;
  /* Support for IE. */
  font-feature-settings: 'liga';
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.8em;
}

h3 {
  font-size: 1.6em;
}

p {
  font-size: 1em;
}

* {
  /* font-family: "Roboto"; */
}
